<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <ul>
      <li><a href="https://twitter.com/anapple07_jp" target="_blank" rel="noopener"><font-awesome-icon :icon="['fab', 'twitter']" /></a></li>
      <li><a href="https://www.linkedin.com/in/anapple07/" target="_blank" rel="noopener"><font-awesome-icon :icon="['fab', 'linkedin']" /></a></li>
      <li><a href="https://github.com/anapple07" target="_blank" rel="noopener"><font-awesome-icon :icon="['fab', 'github']" /></a></li>
      <li><a href="https://anapple07.tumblr.com/" target="_blank" rel="noopener"><font-awesome-icon :icon="['fab', 'tumblr']" /></a></li>
      <li><a href="https://booklog.jp/users/anapple07" target="_blank" rel="noopener"><font-awesome-icon :icon="['fas', 'book']" /></a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #808080;
}
</style>
