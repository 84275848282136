import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueGtag from "vue-gtag";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import {
    faTwitter,
    faLinkedin,
    faGithub,
    faTumblr
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
    faTwitter,
    faLinkedin,
    faGithub,
    faTumblr,
    fas
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.use(VueGtag, {
  config: { id: "G-B2436T92XB" }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
